import moment from "moment";

const DateTimeUtils = {
    format: (date, formatString) => {
        return moment(date).format(formatString);
    },
    startOfWeek: () => {
        return moment().startOf('isoWeek').toDate();
    },
    endOfWeek: () => {
        return moment().endOf('isoWeek').toDate();
    },
    addWeeks: (a: Date, weeks: number) => {
        return moment(a).clone().add(weeks, 'weeks').toDate();
    }
};

export default DateTimeUtils;
