import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { DateRange, DirectionsCar, Event } from "@material-ui/icons";
import { useGetOne } from "ra-core";
import React, { useEffect, useState } from "react";
import StatWidget from "./StatWidget";

const styles = {};

export default withStyles(styles)((props) => {
  const [result, setResult] = useState<any>({
    drivers: {
      total: 0,
      currentMonthRegistrations: 0,
      todaysRegistrations: 0,
    },
  });

  const { data, loading } = useGetOne("stats/basic", "BASIC_STATS", {});
  useEffect(() => {
    if (data) {
      setResult({
        drivers: {
          total: data.stats.drivers.total,
          currentMonthRegistrations:
            data.stats.drivers.currentMonthRegistrations,
          todaysRegistrations: data.stats.drivers.todaysRegistrations,
        },
      });
    }
  }, [data]);

  const LoadingContainer = () => (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress color="secondary" />
    </div>
  );

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          {result ? (
            <StatWidget
              title="Total de conductores"
              value={result.drivers.total}
              icon={
                <DirectionsCar style={{ fontSize: 60 }} color="secondary" />
              }
            />
          ) : (
            <LoadingContainer />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {result ? (
            <StatWidget
              title="Registros de conductores hoy"
              value={result.drivers.todaysRegistrations}
              icon={<Event style={{ fontSize: 60 }} color="secondary" />}
            />
          ) : (
            <LoadingContainer />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {result ? (
            <StatWidget
              title="Registros de conductores este mes"
              value={result.drivers.currentMonthRegistrations}
              icon={<DateRange style={{ fontSize: 60 }} color="secondary" />}
            />
          ) : (
            <LoadingContainer />
          )}
        </Grid>
      </Grid>
    </div>
  );
});
