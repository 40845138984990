/* global $ */
/* global io */
import AdminMainCard from "@main-components/main-card";
import SignupChart from "@main-components/signup-chart";
import { Tab, TabPanel, Tabs } from "@main-components/tabs";
import StatWidget from "@main/dashboard/components/StatWidget";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { DateRange, DirectionsCar, Event } from "@material-ui/icons";
import {
  checkPermission,
  permissionsConstants,
} from "@misc/auth-provider/permissions";
import React, { useEffect, useState } from "react";
import { Title, useGetOne, WithPermissions } from "react-admin";
import ReactApexChart from "react-apexcharts";

const Widget = withStyles({
  root: {
    padding: "15px 15px",
    margin: 10,
  },
})(Paper);


const LoadingContainer = () => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <CircularProgress color="secondary" />
  </div>
);

function Stats(props) {
  const [factStats, setFactStats] = useState<any>({});
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);

  function handleChangeTab(event, newValue) {
    setActiveTab(newValue);
  }
  const { data, loading: loadingStats } = useGetOne(
    "stats/basic",
    "BASIC_STATS",
    {}
  );
  useEffect(() => {
    if (data && !loadingStats) {
      setFactStats(data.stats);
      setLoading(false);
    }
  }, [data, loadingStats]);
  return (
    <AdminMainCard>
      <Title title="Estadísticas"></Title>
      <div>
        <Tabs value={activeTab} onChange={handleChangeTab}>
          <Tab label="General" />
          <Tab label="Conductores" />
          <Tab label="Pasajeros" />
        </Tabs>
        <TabPanel value={activeTab} index={0}>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              {!loading ? (
                <Widget>
                  <h3
                    className="MuiTypography-root"
                    style={{ textAlign: "center" }}
                  >
                    Distribución de vehículos por marca
                  </h3>
                  <ReactApexChart
                    options={{
                      labels: factStats.carBrands.map((cb) => cb._id),
                    }}
                    series={factStats.carBrands.map((cb) => cb.count)}
                    type="pie"
                    height={350}
                  />
                </Widget>
              ) : (
                <LoadingContainer />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {!loading ? (
                <Widget>
                  <h3
                    className="MuiTypography-root"
                    style={{ textAlign: "center" }}
                  >
                    Distribución de vehículos por año
                  </h3>
                  <ReactApexChart
                    options={{
                      labels: factStats.carYears.map((cb) => cb._id.toString()),
                    }}
                    series={factStats.carYears.map((cb) => cb.count)}
                    type="pie"
                    height={350}
                  />
                </Widget>
              ) : (
                <LoadingContainer />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12} style={{ width: 300 }}>
              {!loading ? (
                <Widget>
                  <h3
                    className="MuiTypography-root"
                    style={{ textAlign: "center" }}
                  >
                    Top 10 vehículos más usados
                  </h3>
                  <ReactApexChart
                    options={{
                      tooltip: {
                        y: {
                          formatter: (value) => {
                            return (
                              value +
                              (value > 1 ? " conductores" : " conductor")
                            );
                          },
                        },
                      },
                      plotOptions: {
                        bar: {
                          distributed: true,
                        },
                      },
                      labels: factStats.carModels.map(
                        (r) => r._id /* + " " + r.carModel */
                      ),

                      responsive: [
                        {
                          breakpoint: 500,
                          options: {
                            legend: {
                              position: "bottom",
                            },
                          },
                        },
                      ],
                    }}
                    series={[
                      {
                        name: "Top 10 vehículos más usados",
                        data: factStats.carModels.map((r) => r.count),
                      },
                    ]}
                    type="bar"
                    height={350}
                  />
                </Widget>
              ) : (
                <LoadingContainer />
              )}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              {!loading ? (
                <StatWidget
                  title="Total de conductores"
                  value={factStats.drivers.total}
                  icon={
                    <DirectionsCar style={{ fontSize: 60 }} color="secondary" />
                  }
                />
              ) : (
                <LoadingContainer />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {!loading ? (
                <StatWidget
                  title="Registros de conductores hoy"
                  value={factStats.drivers.todaysRegistrations}
                  icon={<Event style={{ fontSize: 60 }} color="secondary" />}
                />
              ) : (
                <LoadingContainer />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {!loading ? (
                <StatWidget
                  title="Registros de conductores este mes"
                  value={factStats.drivers.currentMonthRegistrations}
                  icon={
                    <DateRange style={{ fontSize: 60 }} color="secondary" />
                  }
                />
              ) : (
                <LoadingContainer />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={5}>
              {!loading ? (
                <Widget>
                  <h3
                    className="MuiTypography-root"
                    style={{ textAlign: "center" }}
                  >
                    Distribución de conductores por sexo
                  </h3>
                  <ReactApexChart
                    options={{
                      labels: ["Hombres", "Mujeres"],
                      colors: ["#0095ff", "#ff0000"],
                    }}
                    series={[
                      factStats.drivers.maleDriversCount,
                      factStats.drivers.femaleDriversCount,
                    ]}
                    type="pie"
                    height={350}
                  />
                </Widget>
              ) : (
                <LoadingContainer />
              )}
            </Grid>
            <Grid item xs={12} md={7}>
              {!loading ? (
                <Widget>
                  <h3
                    className="MuiTypography-root"
                    style={{ textAlign: "center" }}
                  >
                    Distribución de conductores por edad
                  </h3>
                  <ReactApexChart
                    options={{
                      tooltip: {
                        y: {
                          formatter: (value) => {
                            return (
                              value +
                              (value > 1 ? " conductores" : " conductor")
                            );
                          },
                        },
                      },
                      plotOptions: {
                        bar: {
                          distributed: true,
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                          return val as any;
                        },
                      },
                      labels: factStats.drivers.ageRanges.map((r) => r._id),
                      colors: [
                        "#0095ff",
                        "rgb(240, 129, 13)",
                        "rgb(90, 13, 240)",
                      ],
                    }}
                    series={[
                      {
                        name: "Distribucion de conductores por edad",
                        data: factStats.drivers.ageRanges.map((r) => r.count),
                      },
                    ]}
                    type="bar"
                    height={350}
                  />
                </Widget>
              ) : (
                <LoadingContainer />
              )}
            </Grid>
          </Grid>

          <Widget>
            <div
              style={{
                width: "90%",
                margin: "0 auto",
                textAlign: "center",
                marginBottom: 50,
              }}
            >
              <Typography
                variant="h5"
                component="h3"
                style={{ textAlign: "center", marginBottom: 50 }}
              >
                Historial de registros de conductores
              </Typography>
              <div style={{ marginTop: 20 }}>
                <SignupChart
                  /*  @ts-ignore  */
                  title="Registro de conductores"
                  collection="drivers"
                />
              </div>
            </div>
          </Widget>
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              {!loading ? (
                <StatWidget
                  title="Total de pasajeros"
                  value={factStats.passengers.total}
                  icon={
                    <DirectionsCar style={{ fontSize: 60 }} color="secondary" />
                  }
                />
              ) : (
                <LoadingContainer />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {!loading ? (
                <StatWidget
                  title="Registros de pasajeros hoy"
                  value={factStats.passengers.todaysRegistrations}
                  icon={<Event style={{ fontSize: 60 }} color="secondary" />}
                />
              ) : (
                <LoadingContainer />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {!loading ? (
                <StatWidget
                  title="Registros de pasajeros este mes"
                  value={factStats.passengers.currentMonthRegistrations}
                  icon={
                    <DateRange style={{ fontSize: 60 }} color="secondary" />
                  }
                />
              ) : (
                <LoadingContainer />
              )}
            </Grid>
          </Grid>
          <Widget>
            <div
              style={{
                width: "90%",
                margin: "0 auto",
                textAlign: "center",
                marginBottom: 50,
              }}
            >
              <Typography
                variant="h5"
                component="h3"
                style={{ textAlign: "center", marginBottom: 50 }}
              >
                Historial de registros de pasajeros
              </Typography>

              <div style={{ marginTop: 20 }}>
                <SignupChart
                  /*  @ts-ignore  */
                  title="Registro de pasajeros"
                  collection="passengers"
                />
              </div>
            </div>
          </Widget>
        </TabPanel>
      </div>
    </AdminMainCard>
  );
}

/* 
Stats = withStyles(styles)(Stats);
*/

const withPermission = ({ location, match }) => (
  <WithPermissions
    authParams={{ key: match.path }}
    location={location}
    render={({ permissions }) => {
      return checkPermission(permissions, permissionsConstants.SEE_STATS) ? (
        <Stats permissions={permissions} />
      ) : (
        <p>Prohibido</p>
      );
    }}
  />
);

export default withPermission;
