import polyglotI18nProvider from "ra-i18n-polyglot";
import spanishCustomMessages from "./translation-files/es.json";

const messages = {
  es: { ra: spanishCustomMessages.app, ...spanishCustomMessages },
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "es");
const TranslationProvider = i18nProvider;

export default TranslationProvider;
