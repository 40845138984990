import { withStyles } from "@material-ui/core";
import {
  checkPermission,
  permissionsConstants,
} from "@misc/auth-provider/permissions";
import React from "react";
import {
  CardActions,
  EditButton,
  ReferenceField,
  Responsive,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";

export default function AccountShow({ classes, permissions, ...props }: any) {
  return (
    <Show
      {...props}
      title={<ShowTitle />}
      actions={<ShowActions permissions={permissions} />}
    >
      <SimpleShowLayout>
        <Responsive
          small={<SmallDevicesLayout classes={classes} />}
          medium={
            <TabbedShowLayout>
              <Details wrapper={Tab} label="Detalle" />
            </TabbedShowLayout>
          }
        />
      </SimpleShowLayout>
    </Show>
  );
}

const ShowTitle = ({ record }: any) => {
  return <span>Cuenta {record ? `${record.email}` : ""}</span>;
};

const Details = (props) => {
  return (
    <props.wrapper {...props}>
      <TextField source="email" />

      <TextField source="firstName" />
      <TextField source="lastName" />
      <ReferenceField source="roleId" reference="admin-roles">
        <TextField source="name" />
      </ReferenceField>
    </props.wrapper>
  );
};

const SmallDevicesLayout = (props) => {
  return (
    <div>
      <Details {...props} wrapper={Tab} label="Detalle" />
    </div>
  );
};

const ShowActions = withStyles({
  button: {
    marginBottom: 10,
  },
})(({ basePath, data, resource, classes, permissions }: any) =>
  data ? (
    <CardActions>
      {permissions &&
        checkPermission(permissions, permissionsConstants.EDIT_ADMINS) && (
          <EditButton
            label="Editar"
            basePath={basePath}
            resource={resource}
            record={data}
            className={classes.button}
            variant="contained"
          />
        )}
    </CardActions>
  ) : (
    <div></div>
  )
);
