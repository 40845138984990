import { withStyles } from "@material-ui/core";
import {
  checkPermission,
  flattenPermissionsConstantsDescriptions,
  permissionsConstants,
} from "@misc/auth-provider/permissions";
import React from "react";
import {
  ArrayField,
  BooleanField,
  CardActions,
  EditButton,
  FunctionField,
  Responsive,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";

export default function AdminRoleShow({ classes, permissions, ...props }: any) {
  return (
    <Show
      {...props}
      title={<ShowTitle />}
      actions={<ShowActions permissions={permissions} />}
    >
      <SimpleShowLayout>
        <Responsive
          small={<SmallDevicesLayout classes={classes} />}
          medium={
            <TabbedShowLayout>
              <Details wrapper={Tab} label="Detalle" />
            </TabbedShowLayout>
          }
        />
      </SimpleShowLayout>
    </Show>
  );
}

const ShowTitle = ({ record }: any) => {
  return <span>Rol {record ? `${record.name}` : ""}</span>;
};

const Details = (props) => {
  return (
    <props.wrapper {...props}>
      <TextField source="name" label="Nombre" />

      <BooleanField source="isSuperAdmin" label="Super admin" />

      {props.record && !props.record.isSuperAdmin && (
        <ArrayField source="permissions" label="Permisos">
          <SingleFieldList linkType={false}>
            <FunctionField
              render={(constant) => (
                <div>{`${flattenPermissionsConstantsDescriptions[constant]}, `}</div>
              )}
            />
          </SingleFieldList>
        </ArrayField>
      )}
    </props.wrapper>
  );
};

const SmallDevicesLayout = (props) => {
  return (
    <div>
      <Details {...props} wrapper={Tab} label="Detalle" />
    </div>
  );
};

const ShowActions = withStyles({
  button: {
    marginBottom: 10,
  },
})(({ basePath, data, resource, classes, permissions }: any) =>
  data ? (
    <CardActions>
      {permissions &&
        checkPermission(permissions, permissionsConstants.EDIT_ADMIN_ROLES) && (
          <EditButton
            label="Editar"
            basePath={basePath}
            resource={resource}
            record={data}
            className={classes.button}
            variant="contained"
          />
        )}
    </CardActions>
  ) : (
    <div></div>
  )
);
