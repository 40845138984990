// @ts-nocheck
import { withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";


function AdminMainCard(props) {
  return (
    <Card style={{ borderRadius: 10, width: "100%",  }}>
      <CardContent style={{ background: "white", padding: 20 }}>
        {props.children}
      </CardContent>
    </Card>
  );
}

export default AdminMainCard;
