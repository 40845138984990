import React from "react";
import { Create, required, SimpleForm, TextInput } from "react-admin";

export default function CarBrandCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" label="Nombre" validate={required()} />
      </SimpleForm>
    </Create>
  );
}
