import Configurations from "@main/configurations";
import ForgotPassword from "@main/forgot-password";
import ResetPassword from "@main/reset-password";
import Stats from "@main/stats";
import * as React from "react";
import { Route } from "react-router-dom";

export default [
  <Route exact path="/settings" render={() => <Configurations />} />,
  <Route exact path="/forgot-password" component={ForgotPassword} noLayout />,
  <Route
    exact
    path="/reset-password/:token"
    component={ResetPassword}
    noLayout
  />,
  <Route exact path="/stats" component={Stats} />,
];
