import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Inbox from "@material-ui/icons/Inbox";
import inflection from "inflection";
import * as React from "react";
import { cloneElement, FC, ReactElement } from "react";
import {
  ClassesOverride,
  CreateButton,
  useListContext,
  useTranslate,
} from "react-admin";

const useStyles = makeStyles(
  (theme) => ({
    message: {
      textAlign: "center",
      opacity: theme.palette.type === "light" ? 0.5 : 0.8,
      margin: "0 1em",
      color:
        theme.palette.type === "light" ? "inherit" : theme.palette.text.primary,
    },
    icon: {
      width: "9em",
      height: "9em",
      margin: "0 auto",
      /*  
      marginBottom: 10, */
    },
    toolbar: {
      textAlign: "center",
      marginTop: "2em",
    },
  }),
  { name: "RaEmpty" }
);

const Empty: FC<EmptyProps> = (props) => {
  const {
    title,
    subtitle,
    hideActions = false,
    icon = <Inbox />,
    actions,
    resourceOverride,
    basePath: basePathOverride,
    ...rest
  } = props;

  const { resource: listResource, basePath: listBasePath } =
    useListContext(rest);

  const resource = resourceOverride ? resourceOverride : listResource;
  const basePath = basePathOverride ? basePathOverride : listBasePath;

  const classes = useStyles(rest);
  const translate = useTranslate();

  const resourceName = translate(`resources.${resource}.forcedCaseName`, {
    smart_count: 0,
    _: inflection.humanize(
      translate(`resources.${resource}.name`, {
        smart_count: 0,
        _: inflection.pluralize(resource),
      }),
      true
    ),
  });

  const emptyMessage = translate("ra.page.empty", { name: resourceName });
  const inviteMessage = translate("ra.page.invite");

  const subtitleText = subtitle && subtitle !== " " ? subtitle : null;

  return (
    <>
      <div className={classes.message}>
        {cloneElement(icon, {
          className: classes.icon + " mb-4 mt-4",
        })}
        <Typography
          variant="h5"
          paragraph
          className={!subtitleText ? "mb-0" : ""}
        >
          {!title
            ? translate(`resources.${resource}.empty`, {
                _: emptyMessage,
              })
            : translate(title)}
        </Typography>
        <Typography variant="body1" style={{ maxWidth: 400, margin: "0 auto" }}>
          {!subtitle
            ? translate(`resources.${resource}.invite`, {
                _: inviteMessage,
              })
            : subtitleText && translate(subtitleText)}
        </Typography>
      </div>

      {!hideActions && (
        <div className={classes.toolbar}>
          {actions ? (
            cloneElement(actions, {
              basePath,
              resourceName,
            })
          ) : (
            <CreateButton variant="contained" basePath={basePath} />
          )}
        </div>
      )}
    </>
  );
};

export interface EmptyProps {
  classes?: ClassesOverride<typeof useStyles>;
  icon?: ReactElement;
  actions?: ReactElement;
  hideActions?: boolean;
  title?: string;
  subtitle?: string;
  resourceOverride?: string;
  basePath?: string;
}

export default Empty;
