import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createRequest } from "@misc/data-provider";
import React, { useState } from "react";
import {
  CardActions,
  required,
  SelectInput,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate
} from "react-admin";
import { Field, Form } from "react-final-form";
import { serviceCategories } from "../../list";

const ApproveButton = (props) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  if (props.record.documentsApproved) return null;

  return (
    <span>
      <Button
        {...props}
        disabled={isLoading || props.disabled}
        onClick={() => setOpenAlert(true)}
        variant="contained"
        color="primary"
        style={{ margin: 0, color: "white !important", backgroundColor: "green" }}
      >
        Aprobar cuenta
        {isLoading && (
          <CircularProgress
            size={20}
            style={{ marginLeft: 10, color: "white" }}
          />
        )}
      </Button>

      <Dialog
        open={openAlert}
        onClose={() => setOpenAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Aprobar cuenta"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seleccion la categoria para el conductor
          </DialogContentText>

          <ApproveForm
            setOpenAlert={setOpenAlert}
            record={props.record}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </DialogContent>
      </Dialog>
    </span>
  );
};

function ApproveForm({ setOpenAlert, record, isLoading, setIsLoading }) {
  const notify = useNotify();
  const refresh = useRefresh();

  const action = async (values) => {
    setOpenAlert(false);
    setIsLoading(true);
    createRequest(
      "drivers/approve",
      { driverId: record.id, serviceType: values.carCategory },
      "POST",
      true
    )
      .then((res) => {
        if (res.ok) {
          notify(
            "Se aprobado la documentación y se ha habilitado al conductor"
          );
        } else {
          notify("Ha ocurrido un error al aprobar al conductor");
        }

        refresh();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Form
      onSubmit={action}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div>
            <div>
              <Field
                autoFocus
                id="carCategory"
                name="carCategory"
                component={SelectInput}
                label={"Categoria"}
                disabled={isLoading}
                choices={serviceCategories}
                validate={required()}
                fullWidth
              />
            </div>
          </div>
          <CardActions
          /* @ts-ignore */
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "grey",
                marginRight: 30,
                color: "white",
              }}
              disabled={isLoading}
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isLoading}
            >
              {isLoading && <CircularProgress size={18} thickness={2} />}
              Aprobar
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
}
export default ApproveButton;
