import Empty from "@main-components/empty";
import AdminMainCard from "@main-components/main-card";
import {Card} from "@material-ui/core";
import {checkPermission, permissionsConstants,} from "@misc/auth-provider/permissions";
import React from "react";
import {
    CardActions,
    CreateButton,
    Datagrid,
    DateField,
    ExportButton,
    FunctionField,
    List,
    Responsive,
    SimpleList,
    TextField,
} from "react-admin";

export default function PassengerList(props) {
    return (
        <List
            {...props}
            component={AdminMainCard}
            bulkActionButtons={false}
            sort={{field: "createdAt", order: "DESC"}}
            actions={<ListActions permissions={props.permissions}/>}
            empty={
                <Card style={{padding: 20}}>
                    <Empty
                        hideActions={true}
                        subtitle={" "}
                    />
                </Card>
            }
        >
            <Responsive
                small={
                    <SimpleList
                        primaryText={(record) => record.firstName + " " + record.lastName}
                        secondaryText={(record) => record.email}
                        linkType="show"
                    />
                }
                medium={
                    <Datagrid
                        style={{backgroundColor: "white"}}
                        rowClick="show"
                    >
                        <TextField
                            source="email"
                            label="Email"
                        />
                        <TextField
                            source="firstName"
                            label="Nombre"
                        />
                        <TextField
                            source="lastName"
                            label="Apellido"
                        />

                        {/*  <TextField source="phone" label="Teléfono" />*/}

                        <FunctionField
                            source="phone"
                            label="Teléfono"
                            render={(d) => {
                                return `+56${d.phone}`;
                            }}
                        />

                        <DateField
                            source="createdAt"
                            label="Fecha registro"
                            showTime
                        />
                    </Datagrid>
                }
            />
        </List>
    );
}

const ListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    hasCreate,
    permissions,
}: any) => (
    <CardActions>
        {bulkActions &&
            React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems,
            })}
        {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: "button",
                variant: "contained",
            })}

        {hasCreate && (
            <CreateButton
                basePath={basePath}
                resource={resource}
                variant="contained"
            />
        )}

        {permissions &&
            checkPermission(permissions, permissionsConstants.EXPORT_PASSENGERS) && (
                <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    exporter={exporter}
                    maxResults={10000}
                    variant="contained"
                />
            )}
    </CardActions>
);
