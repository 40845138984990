import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React from "react";

const styles = {
  iconColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const Widget = withStyles({
  root: {
    padding: "15px 15px",
    margin: 10,
    borderRadius: 10,
    borderColor: "black",
    borderWidth: 2,
    borderStyle: "solid",
    boxShadow: "none !important",
  },
})(Paper);

export default withStyles(styles)((props: any) => {
  return (
    <Widget>
      <Typography
        variant="h5"
        component="h4"
        style={{ marginBottom: 10, color: "#000" }}
      >
        {props.title}
      </Typography>
      <Grid container spacing={4} alignItems="center">
        <Grid
          item
          xs={12}
          md={4}
          className={props.classes.iconColumn}
          alignItems="center"
        >
          {props.icon}
        </Grid>

        <Grid item xs={12} md={8} alignItems="center">
          <Typography
            component="h1"
            variant="h1"
            style={{
              textAlign: "center",
              fontSize: 40,
              color: "#000",
              fontWeight: 800,
              lineHeight: 1.2,
            }}
          >
            {props.value}
          </Typography>
        </Grid>
      </Grid>
    </Widget>
  );
});
