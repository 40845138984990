import {Theme} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/styles";
import {baseTheme} from "@theme";
import classnames from "classnames";
import PropTypes from "prop-types";
import {TitleComponent, useCheckAuth} from "ra-core";
import React, {
  ComponentType,
  createElement,
  HtmlHTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {Notification} from "react-admin";
import {StaticContext} from "react-router";
import {useHistory} from "react-router-dom";
import DefaultLoginForm from "./components/login-form";

export interface LoginProps
    extends Omit<HtmlHTMLAttributes<HTMLDivElement>, "title"> {
    backgroundImage?: string;
    children?: ReactNode;
    classes?: object;
    className?: string;
    notification?: ComponentType;
    staticContext?: StaticContext;
    theme?: object;
    title?: TitleComponent;
}

const useStyles = makeStyles(
    (theme: Theme) => ({
        main: {
            background: "#E5E5E5",
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "center",
        },

        avatar: {
            height: 120,
            background: "black",
            margin: 0,
            justifyContent: "center",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 8,
            paddingBottom: 8,
            alignItems: "center",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
        },
        icon: {
            backgroundColor: theme.palette.secondary[500],
        },


        card: {
            background: "white",
            borderRadius: 25,

            [theme.breakpoints.up("md")]: {
                minWidth: 450,
            },
            [theme.breakpoints.down("xs")]: {
                minWidth: 300,
            },
            margin: 0,
            height: 400,
            backgroundColor: "white",
        },
        formWrapper: {
            paddingLeft: 50,
            paddingRight: 50,
            /*  paddingTop: 20,*/
            paddingBottom: 50,
        },
        electroBars: {
            height: 120,
            position: "absolute",
            right: 16,
            bottom: 0,
            [theme.breakpoints.down("xs")]: {
                height: 85,
            },
        },
    }),
    {name: "RaLogin"}
);

const Login: React.FunctionComponent<LoginProps> = (props) => {
    const {
        theme,
        title,
        classes: classesOverride,
        className,
        children,
        notification,
        staticContext,
        backgroundImage,
        ...rest
    } = props;
    const containerRef = useRef<HTMLDivElement>();
    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
    const classes = useStyles(props);

    let backgroundImageLoaded = false;
    const checkAuth = useCheckAuth();
    const history = useHistory();

    const [showForm, setShowForm] = useState(false);
    useEffect(() => {
        checkAuth({}, false)
        .then(() => {
            // already authenticated, redirect to the home page
            history.push("/");
        })
        .catch(() => {
            // not authenticated, stay on the login page
        });
    }, [checkAuth, history]);

    const updateBackgroundImage = () => {
        if (!backgroundImageLoaded && containerRef.current) {
            containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
            backgroundImageLoaded = true;
        }
    };

    // Load background image asynchronously to speed up time to interactive
    const lazyLoadBackgroundImage = () => {
        if (backgroundImage) {
            const img = new Image();
            img.onload = updateBackgroundImage;
            img.src = backgroundImage;
        }
    };

    useEffect(() => {
        if (!backgroundImageLoaded) {
            lazyLoadBackgroundImage();
        }
    });

    return (
        <ThemeProvider theme={muiTheme}>
            <div
                className={classnames(classes.main, className)}
                {...rest}
                /* @ts-ignore */
                ref={containerRef}
                id="loginPage"
            >

                <Card className={classes.card}>

                    <div className={classes.avatar}>
                        <img
                            src="/img/logo_auth.png"
                            style={{width: 250, height: 150}}
                            alt=""
                        />
                    </div>

                    <div style={{justifyContent: "center", display: "flex", margin: 10}}>
                        <h2 style={{margin: 0}}>Panel administrativo</h2>
                    </div>
                    <div className={classes.formWrapper}>{children}</div>
                </Card>
                {/* @ts-ignore */}
                {notification ? createElement(notification) : null}

            </div>
        </ThemeProvider>
    );
};

Login.propTypes = {
    backgroundImage: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.object,
    staticContext: PropTypes.object,
};

Login.defaultProps = {
    theme: baseTheme,
    children: <DefaultLoginForm/>,
    /* @ts-ignore */
    notification: Notification,
};

export default Login;
