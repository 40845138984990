import {Theme, useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {checkPermission, permissionsConstants,} from "@misc/auth-provider/permissions";
import classnames from "classnames";
// @ts-ignore
import inflection from "inflection";
import lodashGet from "lodash/get";
import PropTypes from "prop-types";
import * as React from "react";
import {FC, ReactNode} from "react";
import {getResources, MenuItemLink, ReduxState, useTranslate, WithPermissions,} from "react-admin";
import {shallowEqual, useSelector} from "react-redux";

export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(
    (theme) => ({
        main: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            background: "white",
            height: "500px",
            borderRadius: "5px",
            marginLeft: "10px",
            marginRight: "10px",
            backgroundColor: "transparent",
            [theme.breakpoints.only("xs")]: {
                marginTop: 0,
            },
            [theme.breakpoints.up("md")]: {
                marginTop: "1.5em",
            },
        },
        open: {
            width: lodashGet(theme, "menu.width", MENU_WIDTH),
        },
        closed: {
            width: lodashGet(theme, "menu.closedWidth", CLOSED_MENU_WIDTH),
        },
        icon: {
            width: 20,
        },
        icon2: {
            width: 18,
            height: 25,
        },
        listItem: {
            background: "white",
            borderRadius: 10,
            color: "black",
            marginBottom: 10,
            "&.main": {
                background: "rgb(222, 232, 233)",
                "&:hover": {
                    background: "rgb(222, 232, 233)",
                },
            },
            "&:hover": {
                background: "white",
            },
        },
    }),
    {name: "RaMenu"}
);


const MenuIcon = ({classes, iconSrc}) => (
    <div className={classes.icon}>
        <img
            src={iconSrc}
            style={{width: "100%"}}
        />

    </div>
);

const Menu: FC<MenuProps> = (props) => {
    const {
        classes: classesOverride,
        className,
        dense,
        hasDashboard,
        onMenuClick,
        logout,
        ...rest
    } = props;
    const translate = useTranslate();
    const classes = useStyles(props);
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("xs")
    );
    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources, shallowEqual) as Array<any>;

    return (
        <div
            className={classnames(
                classes.main,
                {
                    [classes.open]: open,
                    [classes.closed]: !open,
                },
                className
            )}
            {...rest}
        >
            <MenuItemLink
                className={classes.listItem}
                to="/"
                primaryText="Dashboard"
                leftIcon={
                    <i className={"fa fa-home"}/>
                }
            />

            <WithPermissions
                render={({permissions}) =>
                    checkPermission(permissions, permissionsConstants.LIST_DRIVERS) ? (
                        <MenuItemLink
                            className={classes.listItem}
                            to="/drivers"
                            primaryText="Conductores"
                            leftIcon={
                                <i className={"fa fa-user"}/>
                            }
                        />
                    ) : (
                        <div></div>
                    )
                }
            />

            <WithPermissions
                render={({permissions}) =>
                    checkPermission(permissions, permissionsConstants.LIST_DRIVERS) ? (
                        <MenuItemLink
                            className={classes.listItem}
                            to="/drivers_earnings"
                            primaryText="Ganancias conductores"
                            leftIcon={
                                <i className={"fa fa-user"}/>
                            }
                        />
                    ) : (
                        <div></div>
                    )
                }
            />

            <WithPermissions
                render={({permissions}) =>
                    checkPermission(permissions, permissionsConstants.LIST_PASSENGERS) ? (
                        <MenuItemLink
                            className={classes.listItem}
                            to="/passengers"
                            primaryText="Pasajeros"
                            leftIcon={
                                <i className={"fa fa-user"}/>
                            }
                        />
                    ) : (
                        <div></div>
                    )
                }
            />

            <WithPermissions
                render={({permissions}) =>
                    checkPermission(permissions, permissionsConstants.LIST_CAR_BRAND) ? (
                        <MenuItemLink
                            className={classes.listItem}
                            to="/car-brands"
                            primaryText="Marca de vehiculos"
                            leftIcon={
                                <i className={"fa fa-tag"}/>
                            }
                        />
                    ) : (
                        <div></div>
                    )
                }
            />


            <WithPermissions
                render={({permissions}) =>
                    checkPermission(permissions, permissionsConstants.SEE_STATS) ? (
                        <MenuItemLink
                            className={classes.listItem}
                            to="/stats"
                            primaryText="Estadísticas"
                            leftIcon={
                                <i className={"fa fa-area-chart"}/>
                            }
                        />
                    ) : (
                        <div></div>
                    )
                }
            />

            <WithPermissions
                render={({permissions}) =>
                    checkPermission(
                        permissions,
                        permissionsConstants.LIST_ADMIN_ROLES
                    ) ? (
                        <MenuItemLink
                            className={classes.listItem}
                            to="/admin-roles"
                            primaryText="Roles administrativos"
                            leftIcon={
                                <i className={"fa fa-asterisk"}/>
                            }
                        />
                    ) : (
                        <div></div>
                    )
                }
            />

            <WithPermissions
                render={({permissions}) =>
                    checkPermission(permissions, permissionsConstants.LIST_ADMINS) ? (
                        <MenuItemLink
                            className={classes.listItem}
                            to="/accounts"
                            primaryText="Cuentas administrativas"
                            leftIcon={
                                <i className={"fa fa-user-circle"}/>
                            }
                        />
                    ) : (
                        <div></div>
                    )
                }
            />

            <MenuItemLink
                className={classes.listItem}
                to="/settings"
                primaryText="Configuraciones"
                leftIcon={
                    <i className={"fa fa-cog"}/>
                }
            />

            {isXSmall && logout}
        </div>
    );
};

export interface MenuProps {
    classes?: object;
    className?: string;
    dense?: boolean;
    hasDashboard?: boolean;
    logout?: ReactNode;
    onMenuClick?: () => void;
}

Menu.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    dense: PropTypes.bool,
    hasDashboard: PropTypes.bool,
    logout: PropTypes.element,
    onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
    onMenuClick: () => null,
};

export default Menu;
