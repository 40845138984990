import {Card} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React from "react";
import {
    ArrayInput,
    EditContextProvider,
    maxValue,
    minValue,
    NumberInput,
    required,
    SaveButton,
    SimpleForm,
    SimpleFormIterator,
    Title,
    Toolbar,
    useEditController,
} from "react-admin";

export default function Configurations() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Card>
            <Title title="Configuraciones del sistema"></Title>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Búsqueda" {...a11yProps(0)} />
                    <Tab label="Tarifas de viajes" {...a11yProps(1)} />
                    <Tab label="Comisiones" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel
                value={value}
                index={0}
            >
                <EditSearchConfigs/>
            </TabPanel>
            <TabPanel
                value={value}
                index={1}
            >
                <EditFeesConfigs/>
            </TabPanel>

            <TabPanel
                value={value}
                index={2}
            >
                <EditCommissionConfigs/>
            </TabPanel>
        </Card>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{p: 3}}>{children}</Box>}
        </div>
    );
}

const EditSearchConfigs = (props) => {
    const controllerProps = useEditController({
        basePath: "/settings",
        id: "SEARCH_CONFIGS",
        mutationMode: "pessimistic",
        resource: "configurations/search",
    });

    return (
        <EditContextProvider value={controllerProps}>
            <SimpleForm {...controllerProps} toolbar={<EditToolbar/>}>
                <NumberInput
                    source="acceptanceTimeout"
                    helperText="Tiempo máximo que tiene un conductor para aceptar una carrera"
                />

                <NumberInput
                    source="attemptMaxCandidates"
                    helperText="Muestra máxima de conductores cercanos en el intento"
                />

                <NumberInput
                    source="attemptSearchRadius"
                    helperText="Radio de búsqueda en kilometros"
                />
                <NumberInput
                    source="maxAttempts"
                    helperText="Máximo de intentos hasta finalizar la búsqueda"
                />
            </SimpleForm>
        </EditContextProvider>
    );
};

const EditFeesConfigs = (props) => {
    const controllerProps = useEditController({
        basePath: "/settings",
        id: "FEES_CONFIGS",
        mutationMode: "pessimistic",
        resource: "configurations/driver-fees",
    });

    return (
        <EditContextProvider value={controllerProps}>
            <SimpleForm {...controllerProps} toolbar={<EditToolbar/>}>
                <ArrayInput source="feesConfig.mode_auto">
                    <FeeForm/>
                </ArrayInput>

                <ArrayInput source="feesConfig.mode_sub">
                    <FeeForm/>
                </ArrayInput>

                <ArrayInput source="feesConfig.mode_taxi">
                    <FeeForm/>
                </ArrayInput>
            </SimpleForm>
        </EditContextProvider>
    );
};


function FeeForm(props) {
    return (
        <SimpleFormIterator {...props}>
            <NumberInput
                source="distanceRange.min"
                validate={required()}
                helperText="Distancia mínima para aplicar esta tarifa"
            />
            <NumberInput
                source="distanceRange.max"
                helperText="Distancia máxima para aplicar esta tarifa (Opcional)"
            />
            <NumberInput
                source="baseFee.amount"
                validate={required()}
            />
            <NumberInput
                source="kmFee.amount"
                validate={required()}
            />
            <NumberInput
                source="durationFee.amount"
                validate={required()}
            />
        </SimpleFormIterator>
    );
}

const EditCommissionConfigs = (props) => {
    const controllerProps = useEditController({
        basePath: "/settings",
        id: "COMMISSIONS",
        mutationMode: "pessimistic",
        resource: "configurations/commissions",
    });

    return (
        <EditContextProvider value={controllerProps}>
            <SimpleForm {...controllerProps} toolbar={<EditToolbar/>}>
                <NumberInput
                    source="platformFee"
                    validate={[required(), minValue(0, "Mínimo 0"), maxValue(100, "Máximo 100")]}
                />
                <ArrayInput
                    source="taxes"
                    defaultValue={[]}
                >
                    <SimpleFormIterator>
                        <NumberInput
                            source="name"
                            validate={required()}
                        />
                        <NumberInput
                            source="percentage"
                            validate={required()}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </EditContextProvider>
    );
};

const EditToolbar = ({...props}) => (
    <Toolbar {...props} style={{justifyContent: "space-between"}}>
        <SaveButton/>
    </Toolbar>
);
