import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {SimpleShowLayout} from "react-admin";
import {DocumentField} from "./DocumentField";

const useStyles = makeStyles((theme) => ({
    documentWrapper: {
        display: "flex",
        flexFlow: "wrap",
    },
    image: {
        minHeight: "250px",
    },
}));

export function Documents(props) {
    const classes = useStyles();

    return (
        <props.wrapper {...props}>
            <SimpleShowLayout className={classes.documentWrapper}>


                <DocumentField
                    source="documents.licenseFrontUrl"
                    label="Licencia (frente)"
                    addLabel
                />
                <DocumentField
                    source="documents.licenseBackUrl"
                    label="Licencia (reverso)"
                    addLabel
                />

                <DocumentField
                    source="documents.dniFrontUrl"
                    label="Cédula Identidad Frente"
                    addLabel
                />
                <DocumentField
                    source="documents.dniBackUrl"
                    label="Cédula Identidad Reverso"
                    addLabel
                />

                <DocumentField
                    source="documents.judicialRecordsUrl"
                    label="Certificado de antecendentes judiciales"
                    addLabel
                />
            </SimpleShowLayout>
        </props.wrapper>
    );
}
