import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createRequest } from "@misc/data-provider";
import { cloneDeep } from "lodash";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

var es = require("apexcharts/dist/locales/es.json");

const styles = {
  active: {
    background: "#000",
    color: "white",
  },
  inactive: {
    borderColor: "#000",
    color: "#000" + "!important",
  },
};

export default withStyles(styles)(
  /* @ts-ignore */
  ({ title, classes, collection, ...props }) => {
    const [series, setSeries] = useState([
      {
        name: title,
        data: [],
      },
    ]);

    const [isLoading, setIsLoading] = useState(false);

    const [options, setOptions] = useState({
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
        locales: [es],
        defaultLocale: "es",
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
        y: {
          formatter: (value) => {
            return parseInt(value);
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    });

    const [selection, setSelection] = useState("one_month");

    const updateData = useCallback(
      (timeline) => {
        const updateSeriesData = (data) => {
          let seriesCopy = cloneDeep(series);
          seriesCopy[0].data = data;
          setSeries(seriesCopy);
        };

        const getMappedData = (start: any, end: any, callback) => {
          setIsLoading(true);
          const params = {
            start: start.format("YYYY-MM-DD"),
            end: end.format("YYYY-MM-DD"),
            collection: collection,
          };

          const urlParams = new URLSearchParams(params).toString();

          createRequest(
            `stats/signup/${1}?${urlParams}`,
            undefined,
            "GET"
          ).then(async (result) => {
            setIsLoading(false);

            const res = await result.json();

            updateSeriesData(mapData(res.result));
            callback();
          });
        };

        setSelection(timeline);
        let start: any = null,
          end: any = null;
        switch (timeline) {
          case "one_month":
            start = moment().startOf("month");
            end = moment().endOf("month");
            break;
          case "six_months":
            start = moment().subtract(6, "months");
            end = moment();
            break;
          case "one_year":
            start = moment().subtract(1, "year");
            end = moment();

            break;
            /* case 'today':
				start = moment();
				end   = moment(); */
            break;
          default:
        }

        getMappedData(start, end, () => {
          setOptions({
            xaxis: {
              /* @ts-ignore */
              min: start.toDate().getTime(),
              max: end.toDate().getTime(),
            },
          });
        });
      },
      [collection, series]
    );

    useEffect(() => {
      updateData(selection);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mapData = (data) => {
      return data.map((ds, i) => {
        return [new Date(ds._id).getTime(), ds.count];
      });
    };

    const LoadingContainer = () => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          width: "100%",
          height: 350,
          background: "#8080800f",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );

    return (
      <div id="chart" style={{ width: "100%", marginTop: 10 }}>
        <div className="toolbar">
          <Button
            color="secondary"
            id="one_month"
            onClick={() => updateData("one_month")}
            className={
              selection === "one_month" ? classes.active : classes.inactive
            }
          >
            1M
          </Button>
          &nbsp;
          <Button
            color="secondary"
            id="six_months"
            onClick={() => updateData("six_months")}
            className={
              selection === "six_months" ? classes.active : classes.inactive
            }
          >
            6M
          </Button>
          &nbsp;
          <Button
            color="secondary"
            id="one_year"
            onClick={() => updateData("one_year")}
            className={
              selection === "one_year" ? classes.active : classes.inactive
            }
          >
            1A
          </Button>
          &nbsp;
          {/* <Button  color="secondary" id="today" onClick={()=>updateData('today')} className={ (selection==='today' ? props.classes.active : '')}>
					HOY
				</Button>
				&nbsp; */}
          <div id="chart-timeline">
            {isLoading ? <LoadingContainer /> : null}

            <ReactApexChart
              /* @ts-ignore */
              options={options}
              series={series}
              type="area"
              height={350}
            />
          </div>
        </div>
      </div>
    );
  }
);
