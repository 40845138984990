import moment from "moment-timezone";
import React from "react";
import {BooleanField, DateField, FunctionField, SelectField, TextField,} from "react-admin";
import {serviceCategories} from "@main/drivers/list";
import {DocumentField} from "@main/drivers/show/DocumentField";

export function Details(props) {
    return (
        <props.wrapper {...props}>
            <DocumentField
                source="profilePictureUrl"
                label="Foto de perfil"
                addLabel
            />

            <TextField
                source="document"
                label="RUT persona"
            />
            
            <SelectField
                source="gender"
                label="Sexo"
                choices={[
                    {id: "male", name: "Hombre"},
                    {id: "female", name: "Mujer"},
                ]}
            />
            <TextField
                source="email"
                label="Email"
            />
            <TextField
                source="firstName"
                label="Nombre"
            />
            <TextField
                source="lastName"
                label="Apellido"
            />
            {/* <TextField
                source="phone"
                label="Teléfono"
            />
            */}
            <FunctionField
                source="phone"
                label="Teléfono"
                render={(d) => {
                    return `+56${d.phone}`;
                }}
            />
            {/*    <TextField
                source="city"
                label="Ciudad"
            />*/}
            <TextField
                source="address"
                label="Dirección"
            />
            <FunctionField
                source="birthday"
                label="Fecha de nacimiento"
                render={(d) => {
                    return d.birthday
                        ? moment(d.birthday).utc().format("DD/MM/YYYY")
                        : "";
                }}
            />
            <TextField
                source="rating"
                label="Calificación"
            />
            <DateField
                source="createdAt"
                label="Fecha registro"
                showTime
            />
            <BooleanField
                source="documentsApproved"
                label="Documentos verificados"
            />


            <CarCategoryField
                source="carCategory"
                label="Categoria asignada"
                addLabel
            />

            {/*    <FunctionField
                source="additionalInfo.subscriptionEnd"
                label="Fin de suscripción"
                render={(d) => {
                    return d.additionalInfo && d.additionalInfo.subscriptionEnd
                        ? moment(d.additionalInfo.subscriptionEnd)
                        .utc()
                        .format("DD/MM/YYYY")
                        : "-";
                }}
            />*/}
        </props.wrapper>
    );
}


function CarCategoryField(props) {
    return (
        <SelectField
            {...props}
            choices={serviceCategories}
        />
    )
}