import { apiUrl } from "@app";
import AuthLayout from "@main-components/auth-layout";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  PasswordInput,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";

const useStyles = makeStyles(
  (theme) => ({
    container: {
      width: "100%",
      maxWidth: "500px",
      padding: "15px",
      margin: "auto",
      textAlign: "center",
    },
    toolbar: {
      backgroundColor: "transparent",
      justifyContent: "flex-end",
    },
  }),
  { name: "RaToolbar" }
);

export const validatePasswords = ({ password, confirmPassword }: any) => {
  const errors = {} as any;

  if (password && confirmPassword && password !== confirmPassword) {
    errors.confirmPassword = ["settings.reset_password_mismatch"];
  }

  return errors;
};

const verifyTokenRequest = (apiUrl, token) => {
  return fetch(`${apiUrl}/verify-password-reset-link`, {
    method: "POST",
    body: JSON.stringify({ token: token }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

const ResetPassword = (props) => {
  const translate = useTranslate();
  const classes = useStyles(props);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validLink, setValidLink] = useState(false);

  const notify = useNotify();
  const redirect = useRedirect();
  const { token } = props.match.params;

  useEffect(() => {
    setLoading(true);
    verifyTokenRequest(apiUrl, token)
      .then(async (res) => {
        const response = await res.json();

        if (res.status < 200 || res.status >= 300) {
          response.msg
            ? notify(`settings.${response.msg}`, "warning")
            : notify("ra.message.error", "warning");
        } else {
          if (response.ok) {
            setValidLink(true);
          } else {
            setValidLink(false);
            response.msg && notify(`settings.${response.msg}`, "warning");
          }
        }
      })
      .catch((e) => {
        notify("ra.message.error", "warning");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [notify, token]);

  const save = (data) => {
    setSaving(true);

    fetch(`${apiUrl}/change-password-with-token`, {
      method: "POST",
      body: JSON.stringify({
        newPassword: data.password,
        token,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const response = await res.json();

        if (res.status < 200 || res.status >= 300) {
          response.msg
            ? notify(`settings.${response.msg}`, "warning")
            : notify("ra.message.error", "warning");
        } else {
          if (response.ok) {
            notify("settings.password_change_reset_successfully");
            redirect("/login");
          } else {
            response.msg
              ? notify(`settings.${response.msg}`, "warning")
              : notify("ra.message.error", "warning");
          }
        }
      })
      .catch((e) => {
        notify("ra.message.error", "warning");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <AuthLayout {...props}>
      <div className={`${classes.container}`}>
        <img src="/img/storevan-logo.png" className="logo mb-3" />
        <h1 className="h3 mb-3 text-center">
          {translate("ra.page.configure_new_password")}
        </h1>

        {!loading ? (
          <SimpleForm
            saving={saving}
            save={save}
            validate={validatePasswords}
            toolbar={
              <Toolbar className={classes.toolbar}>
                <SaveButton disabled={!validLink} />
              </Toolbar>
            }
          >
            <PasswordInput
              label="settings.reset_new_password"
              source="password"
              validate={[required()]}
              disabled={!validLink}
              fullWidth
            />
            <PasswordInput
              label="settings.reset_confirm_new_password"
              source="confirmPassword"
              validate={[required()]}
              disabled={!validLink}
              fullWidth
            />
          </SimpleForm>
        ) : (
          <p style={{ textAlign: "center" }}>
            {translate("ra.message.loading")}
          </p>
        )}
      </div>
    </AuthLayout>
  );
};
export default ResetPassword;
