import React from "react";
import {
  Create,
  email,
  PasswordInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export default function AccountCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <TextInput source="firstName" validate={required()} />
        <TextInput source="lastName" validate={required()} />
        <TextInput source="email" validate={[required(), email()]} />
        <PasswordInput source="password" validate={[required()]} />
        <ReferenceInput
          source="roleId"
          reference="admin-roles"
          validate={required()}
        >
          <SelectInput source="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
