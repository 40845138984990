import CardActions from "@material-ui/core/CardActions";
import {checkPermission, permissionsConstants,} from "@misc/auth-provider/permissions";
import React from "react";
import {DateField, EditButton, FunctionField, Show, SimpleShowLayout, TextField,} from "react-admin";
import {ShowTitle} from "./ShowTitle";
import {DocumentField} from "@main/drivers/show/DocumentField";

const UserShowActions = ({basePath, data, resource, permissions}: any) =>
    data ? (
        <CardActions
            style={{
                zIndex: 2,
                display: "flex",
                justifyContent: "flex-end",
                flexFlow: "wrap",
            }}
        >
            {permissions &&
                checkPermission(permissions, permissionsConstants.EDIT_PASSENGERS) && (
                    <EditButton
                        label="Editar"
                        basePath={basePath}
                        resource={resource}
                        record={data}
                        variant="contained"
                        onClick={() => {
                        }}
                    />
                )}

            {/*

      {checkPermission(
        permissions,
        permisionsConstants.CHANGE_PASSENGER_PASSWORD
      ) && (
        <ChangePasswordButton
          resource="users"
          variant="contained"
          record={data}
        />
      )}

      {checkPermission(permissions, permisionsConstants.BLOCK_PASSENGERS) && (
        <UserBlockButton record={data} resource="users" />
      )}

    */}
        </CardActions>
    ) : (
        <div></div>
    );

export default function PassengerShow(props) {
    return (
        <Show
            {...props}
            title={<ShowTitle/>}
            actions={<UserShowActions permissions={props.permissions}/>}
        >
            <SimpleShowLayout>
                <DocumentField
                    source="profilePictureUrl"
                    label="Foto de perfil"
                    addLabel
                />
                <TextField
                    source="email"
                    label="Email"
                />
                <TextField
                    source="firstName"
                    label="Nombre"
                />
                <TextField
                    source="lastName"
                    label="Apellido"
                />
                {/*  <TextField
                    source="phone"
                    label="Teléfono"
                />*/}
                <FunctionField
                    source="phone"
                    label="Teléfono"
                    render={(d) => {
                        return `+56${d.phone}`;
                    }}
                />
                <TextField
                    source="run"
                    label="RUN"
                />
                <TextField
                    source="address"
                    label="Dirección"
                />
                <DateField
                    source="createdAt"
                    label="Fecha registro"
                    showTime
                />
            </SimpleShowLayout>
        </Show>
    );
}
