import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, {useState} from "react";
import {CardActions, useNotify, useRefresh} from "react-admin";
import {createRequest} from "@misc/data-provider";
import {Form} from "react-final-form";
import DateTimeUtils from "@misc/utils/date-time-utils";

const ReverseButton = (props) => {
    const [openAlert, setOpenAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    if (props.record.reversed) return (
        <div>
            Reversado el {DateTimeUtils.format(props.record.reversedAt, "YYYY-MM-DD HH:mm")}
        </div>
    )

    if (props.record.paymentMethod != "CREDIT.CARD") return null

    return (
        <span>
      <Button
          {...props}
          disabled={isLoading || props.disabled}
          onClick={() => setOpenAlert(true)}
          variant="contained"
          color={"primary"}
          /* style={{margin: 0, color: "white !important", backgroundColor: "red"}}*/
      >
     Reversar
          {isLoading && (
              <CircularProgress
                  size={20}
                  style={{marginLeft: 10, color: "white"}}
              />
          )}
      </Button>

      <Dialog
          open={openAlert}
          onClose={() => setOpenAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Reversar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           ¿Estás seguro que desear reversar esta transacción?
          </DialogContentText>
            <MyForm
                setOpenAlert={setOpenAlert}
                record={props.record}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
        </DialogContent>
      </Dialog>
    </span>
    );
};


function MyForm({setOpenAlert, record, isLoading, setIsLoading}) {
    const notify = useNotify();
    const refresh = useRefresh();


    const action = async (values) => {
        setOpenAlert(false);
        setIsLoading(true);
        createRequest(
            "payments/refund",
            {tripId: record.tripId},
            "POST",
            true
        )
        .then((res) => {
            if (res.ok) {
                notify(
                    "Se ha reversado correctamente"
                );
            } else {
                notify("Ha ocurrido un error");
            }

            refresh();
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <Form
            onSubmit={action}
            render={({handleSubmit}) => (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                >

                    <CardActions
                        /* @ts-ignore */
                    >
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: "grey",
                                marginRight: 30,
                                color: "white",
                            }}
                            disabled={isLoading}
                            onClick={() => {
                                setOpenAlert(false);
                            }}
                        >
                            Cancelar
                        </Button>

                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={isLoading}
                        >
                            {isLoading && <CircularProgress
                                size={18}
                                thickness={2}
                            />}
                            Reversar
                        </Button>
                    </CardActions>
                </form>
            )}
        />
    );
}

export default ReverseButton;
