import {Typography, withStyles} from "@material-ui/core";
import {checkPermission, permissionsConstants,} from "@misc/auth-provider/permissions";
import React from "react";
import {
    AutocompleteInput,
    CardActions,
    DateInput,
    DeleteButton,
    Edit,
    FormDataConsumer,
    RadioButtonGroupInput,
    SaveButton,
    SelectInput,
    ShowButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useGetList,
} from "react-admin";
import {ShowTitle} from "../show/ShowTitle";

const yearRange = (start, stop, step) => {
    var a = [start],
        b = start;
    while (b < stop) {
        a.push((b += step || 1));
    }
    return a;
};

export const DriverEdit = withStyles({
    input: {
        width: "400px",
    },
})((props: any) => (
    <Edit {...props} title={<ShowTitle/>}
          actions={<EditActions/>}
    >
        <SimpleForm
            toolbar={<EditToolbar permissions={props.permissions}/>}
            mutationMode="pessimistic"
            redirect="show"
        >
            <FormDataConsumer>
                {(formDataProps) => (
                    <div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <Typography variant="subtitle1">Información Básica</Typography>

                            <TextInput
                                className={props.classes.input}
                                source="document"
                                label="DNI"
                            />
                            <TextInput
                                className={props.classes.input}
                                source="firstName"
                                label="Nombre"
                            />
                            <TextInput
                                className={props.classes.input}
                                source="lastName"
                                label="Apellido"
                            />
                            <TextInput
                                className={props.classes.input}
                                source="email"
                                label="Email"
                            />
                            <TextInput
                                className={props.classes.input}
                                source="phone"
                                label="Teléfono"
                            />
                            <DateInput
                                className={props.classes.input}
                                source="birthday"
                                label="Fecha de nacimiento"
                                options={
                                    {
                                        format: "dd-MM-yyyy",
                                    } as any
                                }
                            />
                            <RadioButtonGroupInput
                                className={props.classes.input}
                                label="Sexo"
                                source="gender"
                                choices={[
                                    {id: "male", name: "Hombre"},
                                    {id: "female", name: "Mujer"},
                                ]}
                            />
                            <TextInput
                                className={props.classes.input}
                                disabled
                                source="city"
                                label="Ciudad"
                            />
                            <TextInput
                                className={props.classes.input}
                                disabled
                                source="address"
                                label="Dirección"
                            />
                        </div>
                        {formDataProps && formDataProps.formData.driverType === "OWNER" && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: 20,
                                }}
                            >
                                <Typography variant="subtitle1">Suscripción</Typography>
                                <DateInput
                                    className={props.classes.input}
                                    source="subscriptionEnd"
                                    label="Fin de suscripción"
                                    options={
                                        {
                                            format: "dd-MM-yyyy",
                                        } as any
                                    }
                                />
                            </div>
                        )}
                        {formDataProps && formDataProps.formData.driverType === "OWNER" && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: 20,
                                }}
                            >
                                <Typography variant="subtitle1">
                                    Información del vehículo
                                </Typography>

                                <CardBranInput className={props.classes.input}/>
                                <TextInput
                                    className={props.classes.input}
                                    source="carInfo.carModel"
                                    label="Modelo de vehículo"
                                />

                                <SelectInput
                                    className={props.classes.input}
                                    source="carInfo.carYear"
                                    label="Año del vehículo"
                                    choices={[...yearRange(2004, 2021, 1)].map((i) => {
                                        return {
                                            id: i.toString(),
                                            name: i.toString(),
                                        };
                                    })}
                                />

                                <TextInput
                                    className={props.classes.input}
                                    source="carInfo.patent"
                                    label="Número de placa"
                                />

                            </div>
                        )}
                    </div>
                )}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
));

const EditActions = withStyles({
    button: {
        marginBottom: 10,
    },
})(({basePath, data, resource, classes, permissions}: any) =>
    data ? (
        <CardActions>
            <ShowButton
                basePath={basePath}
                resource={resource}
                record={data}
                className={classes.button}
                variant="contained"
            />
        </CardActions>
    ) : (
        <div></div>
    )
);

function CardBranInput(props) {
    const listProps = useGetList(
        "car-brands",
        {page: 1, perPage: 200000000},
        {field: "name", order: "ASC"}
    );

    return (
        <AutocompleteInput
            {...props}
            label="Marca de vehículo"
            source="carInfo.carBrand"
            choices={Object.values(listProps.data)}
            optionText="name"
            optionValue="name"
        />
    );
}

const EditToolbar = ({permissions, ...props}: any) => (
    <Toolbar {...props} style={{justifyContent: "space-between"}}>
        <SaveButton/>
        {checkPermission(permissions, permissionsConstants.DELETE_DRIVERS) && (
            <DeleteButton
                basePath={props.basePath}
                resource={props.resource}
                record={props.data}
            />
        )}
    </Toolbar>
);
