import { Typography, withStyles } from "@material-ui/core";
import {
  checkPermission,
  permissionsConstants,
  permissionsConstantsDescriptions,
} from "@misc/auth-provider/permissions";
import { required } from "ra-core";
import React from "react";
import {
  BooleanInput,
  CardActions,
  CheckboxGroupInput,
  DeleteButton,
  Edit,
  FormDataConsumer,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
import { validateRoleForm } from "../create";

export default function AdminRoleEdit(props) {
  return (
    <Edit
      mutationMode="pessimistic"
      {...props}
      title={<ShowTitle />}
      actions={<EditActions />}
    >
      <SimpleForm
        validate={validateRoleForm}
        toolbar={<EditToolbar permissions={props.permissions} />}
      >
        <TextInput source="name" label="Nombre" validate={required()} />

        <BooleanInput label="Super admin" source="isSuperAdmin" />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !formData.isSuperAdmin && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1">Permisos</Typography>
                {permissionsConstantsDescriptions.map((g) => {
                  return (
                    <React.Fragment>
                      <CheckboxGroupInput
                        {...rest}
                        options={{ fullWidth: true }}
                        label={g.group}
                        source="permissions"
                        choices={Object.values(
                          Object.keys(g.elements).map((c) => ({
                            id: c,
                            name: g.elements[c],
                          }))
                        )}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
}

const ShowTitle = ({ record }: any) => {
  return <span>Rol {record ? `${record.name}` : ""}</span>;
};

const EditToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props} style={{ justifyContent: "space-between" }}>
    <SaveButton />
    {permissions &&
      props.record &&
      props.record.id !== "SUPER_ADMIN" &&
      checkPermission(permissions, permissionsConstants.DELETE_ADMIN_ROLES) && (
        <DeleteButton
          basePath={props.basePath}
          resource={props.resource}
          record={props.data}
          mutationMode="pessimistic"
        />
      )}
  </Toolbar>
);

const EditActions = withStyles({
  button: {
    marginBottom: 10,
  },
})(({ basePath, data, resource, classes, permissions }: any) =>
  data ? (
    <CardActions>
      <ShowButton
        basePath={basePath}
        resource={resource}
        record={data}
        className={classes.button}
        variant="contained"
      />
    </CardActions>
  ) : (
    <div></div>
  )
);
