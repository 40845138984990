import { withStyles } from "@material-ui/core";
import {
  checkPermission,
  permissionsConstants,
} from "@misc/auth-provider/permissions";
import { required } from "ra-core";
import React from "react";
import {
  CardActions,
  DeleteButton,
  Edit,
  SaveButton,
  ShowButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";

export default function CarBrandEdit(props) {
  return (
    <Edit
      mutationMode="pessimistic"
      {...props}
      title={<ShowTitle />}
      actions={<EditActions />}
    >
      <SimpleForm toolbar={<EditToolbar permissions={props.permissions} />}>
        <TextInput source="name" label="Nombre" validate={required()} />
      </SimpleForm>
    </Edit>
  );
}

const ShowTitle = ({ record }: any) => {
  return <span>Marca {record ? `${record.name}` : ""}</span>;
};

const EditToolbar = ({ permissions, ...props }) => (
  <Toolbar {...props} style={{ justifyContent: "space-between" }}>
    <SaveButton />
    {permissions &&
      checkPermission(permissions, permissionsConstants.DELETE_CAR_BRAND) && (
        <DeleteButton
          basePath={props.basePath}
          resource={props.resource}
          record={props.data}
          mutationMode="pessimistic"
        />
      )}
  </Toolbar>
);

const EditActions = withStyles({
  button: {
    marginBottom: 10,
  },
})(({ basePath, data, resource, classes, permissions }: any) =>
  data ? (
    <CardActions>
      <ShowButton
        basePath={basePath}
        resource={resource}
        record={data}
        className={classes.button}
        variant="contained"
      />
    </CardActions>
  ) : (
    <div></div>
  )
);
