import React from "react";
import Typography from "@material-ui/core/Typography";
import moment from "moment-timezone";
import DateTimeUtils from "@misc/utils/date-time-utils";
import ReverseButton from "@main/drivers-earnings/components/ReverseButton";
import {MoneyUtils} from "@misc/utils/text";

export function Earnings(props) {

    return (
        <props.wrapper {...props}>

            <Content/>
        </props.wrapper>
    );
}


function Content(props) {
    return (
        <div>

            <div
                style={{
                    marginBottom: 20
                }}
            >
                Periodo: {DateTimeUtils.format(DateTimeUtils.startOfWeek(), "DD/MM/YYYY")} - {DateTimeUtils.format(DateTimeUtils.addWeeks(DateTimeUtils.endOfWeek(), 1), "DD/MM/YYYY")}
            </div>
            {props.record.earningSummary.items.map((i) => {

                return (
                    <div
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            borderBottomWidth: 1,
                            borderBottomColor: "grey",
                            paddingBottom: 10,
                            borderBottomStyle: "solid",
                        }}
                    >

                        <div>
                            <Field
                                title={"Fecha"}
                                value={moment(i.date).format('DD/MM/YYYY HH:mm')}
                            />
                        </div>
                        <div style={{display: "flex", gap: 10}}>


                            <Field
                                title={"Origen"}
                                value={i.origin.address}
                            />
                            <Field
                                title={"Destino"}
                                value={i.destination.address}
                            />
                        </div>
                        <div style={{display: "flex", gap: 10}}>

                            <Field
                                title={"Tarifa del viaje"}
                                value={`${MoneyUtils.numberToPrice(i.tripPrice.amount.toFixed(2))}`}
                            />
                            <Field
                                title={"Método de pago"}
                                value={getPaymentMethodName(i.paymentMethod)}
                            />
                            <Field
                                title={"Comisión"}
                                value={`${MoneyUtils.numberToPrice(i.breakdown.platformFee.toFixed(2))} (${i.breakdown.platformFeePercent ?? 0}%)`}
                            />
                        </div>


                        <Field
                            title={"Ganancia"}
                            value={`${MoneyUtils.numberToPrice(i.amount.amount.toFixed(2))}`}
                        />

                        <div style={{marginTop: 10}}>
                            <ReverseButton record={i}/>
                        </div>
                    </div>
                )
            })}

            <div
                style={{
                    marginTop: 20
                }}
            >
                <div>
                    <b>Total de viajes:</b> {props.record.earningSummary.count}
                </div>
                <div
                    style={{
                        marginTop: 10,
                    }}
                >
                    <b>Total efectivo
                        recolectado:</b> {MoneyUtils.numberToPrice(props.record.cashItems.collected.toFixed(2))}
                </div>

                <div
                    style={{
                        marginTop: 10,
                    }}
                >
                    <b>Total ganancia conductor
                        efectivo:</b> {MoneyUtils.numberToPrice(props.record.cashItems.totalEarned.toFixed(2))}
                </div>

                <div
                    style={{
                        marginTop: 10,
                    }}
                >
                    <b>Total ganancia Autodriver
                        efectivo:</b> {MoneyUtils.numberToPrice(props.record.earningSummary.platformEarnings.cash)} ({(props.record.earningSummary.platformEarnings.cashPercent ?? 0).toFixed(0)}%)
                </div>

                <div
                    style={{
                        marginTop: 10,
                    }}
                >
                    <b>Total tarjeta
                        recolectado:</b> {MoneyUtils.numberToPrice(props.record.cardItems.collected.toFixed(2))}
                </div>

                <div
                    style={{
                        marginTop: 10,
                    }}
                >
                    <b>Total ganancia conductor
                        tarjeta:</b> {MoneyUtils.numberToPrice(props.record.cardItems.totalEarned.toFixed(2))}
                </div>

                <div
                    style={{
                        marginTop: 10,
                    }}
                >
                    <b>Total ganancia Autodriver
                        tarjeta:</b> {MoneyUtils.numberToPrice(props.record.earningSummary.platformEarnings.card)} ({(props.record.earningSummary.platformEarnings.cardPercent ?? 0).toFixed(0)}%)
                </div>

                <div
                    style={{
                        marginTop: 10,
                    }}
                >
                    <b>Transferir al
                        conductor: </b> {MoneyUtils.numberToPrice(props.record.earningSummary.transferAmount)}
                </div>
            </div>
        </div>
    )
}

function Field({title, value}) {
    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{marginRight: 10}}>
                <Typography
                    component="span"
                    variant="body2"
                    style={{
                        fontWeight: "bold"
                    }}
                >
                    {title}:
                </Typography>
            </div>
            <div>
                <Typography
                    component="span"
                    variant="body2"
                >
                    {value}
                </Typography>
            </div>
        </div>
    )
}


function getPaymentMethodName(key) {
    switch (key) {
        case 'CASH':
            return 'Efectivo';
        case 'CREDIT.CARD':
            return 'Débito/Crédito';
        case 'QR':
            return 'Código QR';
    }
}
