import React from "react";
import {DateField, SelectField, TextField} from "react-admin";
import {DocumentField} from "./DocumentField";

export function CarInfo(props) {
    return (
        <props.wrapper {...props}>
            <TextField
                source="carInfo.carBrand"
                label="Marca vehículo"
            />
            <TextField
                source="carInfo.carModel"
                label="Modelo vehículo"
            />
            <TextField
                source="carInfo.carYear"
                label="Año vehículo"
            />
            <CarTypeField
                source="carInfo.carType"
                label="Tipo de vehículo"
                addLabel
            />
            <TextField
                source="carInfo.patent"
                label="Patente"
            />
            <DocumentField
                source="carDocuments.carInsuranceUrl"
                label="SOAP"
                addLabel
            />
            <DocumentField
                source="carDocuments.trafficLicenseUrl"
                label="Permiso de circulación"
                addLabel
            />
            <DocumentField
                source="carDocuments.technicalRevisionUrl"
                label="Revisión técnica"
                addLabel
            />

            <DateField
                source="carDocuments.technicalRevisionExpiringDate"
                label="Vencimiento Rev Técnica"
            />

            <DocumentField
                source="carDocuments.vehicularRegisterUrl"
                label="Padrón"
                addLabel
            />
        </props.wrapper>
    );
}

function CarTypeField(props) {
    return (
        <SelectField
            {...props}
            choices={[{
                id: 'car.standard',
                name: 'Estándar'
            },
                {
                    id: 'car.suv',
                    name: 'SUV'
                },
                {
                    id: 'car.taxi',
                    name: 'Taxi'
                }]}
        />
    )
}