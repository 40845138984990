import Empty from "@main-components/empty";
import AdminMainCard from "@main-components/main-card";
import {Card} from "@material-ui/core";
import React from "react";
import {
    CardActions,
    Datagrid,
    downloadCSV,
    ExportButton,
    FunctionField,
    List,
    Responsive,
    SimpleList,
    TextField
} from "react-admin";
import {checkPermission, permissionsConstants} from "@misc/auth-provider/permissions";
import jsonExport from 'jsonexport/dist';
import DateTimeUtils from "@misc/utils/date-time-utils";
import {MoneyUtils} from "@misc/utils/text";

export default function DriverEarningsList(props) {
    const exporter = drivers_earnings => {
        const drivers_earningsForExport = drivers_earnings.map(e => {

            return {
                id: e.id,
                email: e.email,
                name: `${e.firstName} ${e.lastName}`,
                total: `${e.earningSummary.totalEarned}`
            };
        });
        jsonExport(drivers_earningsForExport, {
            headers: ['id', 'email', "name", "total"] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'drivers_earnings'); // download as 'posts.csv` file
        });
    };


    return (

        <>

            <List
                {...props}
                component={AdminMainCard}
                bulkActionButtons={false}
                sort={{field: "createdAt", order: "DESC"}}
                actions={<ListActions permissions={props.permissions}/>}
                empty={
                    <Card style={{padding: 20}}>
                        <Empty
                            hideActions={true}
                            subtitle={" "}
                        />
                    </Card>
                }
                exporter={exporter}
                filters={<div
                    style={{alignSelf: "center"}}
                >
                    Periodo: {DateTimeUtils.format(DateTimeUtils.startOfWeek(), "DD/MM/YYYY")} - {DateTimeUtils.format(DateTimeUtils.addWeeks(DateTimeUtils.endOfWeek(), 1), "DD/MM/YYYY")}
                </div>}
            >
                <Responsive
                    small={
                        <SimpleList
                            primaryText={(record) => record.firstName + " " + record.lastName}
                            secondaryText={(record) => record.email}
                            linkType="show"
                        />
                    }
                    medium={
                        <Datagrid
                            style={{backgroundColor: "white"}}
                            rowClick="show"
                        >
                            <TextField
                                source="email"
                                label="Email"
                            />
                            <TextField
                                source="firstName"
                                label="Nombre"
                            />
                            <TextField
                                source="lastName"
                                label="Apellido"
                            />

                            <FunctionField
                                source="earningSummary"
                                label="Ganancias"
                                render={(record) => {
                                    return (
                                        MoneyUtils.numberToPrice(record.earningSummary.totalEarned)
                                    )
                                }
                                }
                            />

                            <FunctionField
                                source="earningSummary"
                                label="Transferir"
                                render={(record) => {
                                    return (
                                        MoneyUtils.numberToPrice(record.earningSummary.transferAmount)
                                    )
                                }
                                }
                            />
                        </Datagrid>
                    }
                />
            </List>
        </>

    );
}


const ListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total,
    hasCreate,
    permissions,
}: any) => {
    return (
        <CardActions>


            {permissions &&
                checkPermission(permissions, permissionsConstants.EXPORT_DRIVERS) && (
                    <ExportButton
                        disabled={total === 0}
                        resource={resource}
                        sort={currentSort}
                        exporter={exporter}
                        maxResults={10000}
                        variant="contained"


                    />
                )}
        </CardActions>
    );
};
