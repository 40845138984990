import React from "react";
import {
    Responsive,
    Show,
    ShowController,
    SimpleShowLayout,
    Tab,
    TabbedShowLayout,
    TabbedShowLayoutTabs,
} from "react-admin";
import {ShowTitle} from "./ShowTitle";
import {Earnings} from "@main/drivers-earnings/show/Earnings";
import {BankAccount} from "@main/drivers/show/BankAccount";


export default function DriverEarningsShow(props) {
    return (
        <ShowController {...props}>
            {(controllerProps) => (
                <Show
                    {...props}
                    title={<ShowTitle/>}
                >
                    <SimpleShowLayout>
                        <Responsive
                            medium={
                                <TabbedShowLayout
                                    tabs={
                                        <TabbedShowLayoutTabs variant="scrollable" {...props} />
                                    }
                                >
                                    <Earnings
                                        wrapper={Tab}
                                        label="Ganancias"
                                    />
                                    <BankAccount
                                        wrapper={Tab}
                                        label="Datos bancarios"
                                    />
                                </TabbedShowLayout>
                            }
                        />
                    </SimpleShowLayout>
                </Show>
            )}
        </ShowController>
    );
}
