import decodeJwt from "jwt-decode";

export const appToken = "autodriver_admin_panel_token";
export const appProfile = "autodriver_admin_panel_data";

const AuthProvider = (loginUrl) => ({
    login: ({username, password}) => {
        const options = {
            body: JSON.stringify({email: username, password}),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
        };

        return fetch(new URL(loginUrl).toString(), options)
        .then(async (response) => {
            if (response.status < 200 || response.status >= 300) {
                const r = await response.json();
                const message = r.error.split("Error: ")[1];
                throw new Error(
                    r && r.error ? "ra.auth." + message : response.statusText
                );
            }
            return response.json();
        })
        .then(({token}) => {
            const decodedToken = decodeJwt(token);
            localStorage.setItem(appToken, token);

            localStorage.setItem(appProfile, JSON.stringify(decodedToken));
        });
    },
    logout: () => {
        localStorage.removeItem(appToken);
        localStorage.removeItem(appProfile);
        return Promise.resolve();
    },
    checkError: (error) => {
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem(appToken)
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: () => {
        const {rolePermissions, roleId} = JSON.parse(
            localStorage.getItem(appProfile) as string
        );

        return roleId
            ? Promise.resolve({
                isSuperAdmin: roleId === "SUPER_ADMIN",
                permissions: rolePermissions,
            })
            : Promise.resolve("guest");
    },
    getIdentity: () => {
        const {id, firstName, lastName, email} = JSON.parse(
            localStorage.getItem(appProfile) as string
        );

        return Promise.resolve({
            id,
            fullName: email,
            avatar: undefined,
        });
    },
});

export default AuthProvider;
