import React from "react";
import {TextField,} from "react-admin";

export function BankAccount(props) {
    return (
        <props.wrapper {...props}>
            <TextField
                source="bankAccount.name"
                label="Nombre / Razón Social"
            />
            <TextField
                source="bankAccount.bank"
                label="Banco"
            />
            <TextField
                source="bankAccount.type"
                label="Tipo"
            />
            <TextField
                source="bankAccount.number"
                label="Número de cuenta"
            />
            <TextField
                source="bankAccount.email"
                label="Email"
            />
            <TextField
                source="bankAccount.rut"
                label="RUT"
            />
        </props.wrapper>
    );
}

