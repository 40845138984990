import SideBar from "@main-components/side-menu";
import {Box, Typography} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import * as React from "react";
import {AppBar, Layout as BaseLayout, MenuItemLink, Notification, UserMenu,} from "react-admin";

const ConfigurationMenu = React.forwardRef<any, any>(({onClick}, ref) => (
    <MenuItemLink
        ref={ref}
        to="/settings"
        primaryText="Configuraciones"
        leftIcon={<SettingsIcon/>}
        onClick={onClick} // close the menu on click
    />
));

const MyUserMenu = (props) => (
    <UserMenu {...props}>
        <ConfigurationMenu/>
    </UserMenu>
);

const MyAppBar = (props) => {
    return (
        <AppBar
            {...props}
            userMenu={<MyUserMenu/>}
            style={{paddingLeft: "10%", paddingRight: "10%"}}
        >
            <Box
                flex={1}
                display="flex"
                alignItems="center"
            >
                <img
                    src="./img/BarraAdminS.png"
                    style={{height: 50}}
                />
                <Typography
                    variant="h6"
                    color="inherit"
                    style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 20,
                    }}
                    id="react-admin-title"
                />
            </Box>
        </AppBar>
    );
};

const Layout = (props: any) => (
    <BaseLayout
        {...props}
        appBar={MyAppBar}
        sidebar={SideBar}
        //menu={MySideBar}
        notification={Notification}
    />
);

export default Layout;
