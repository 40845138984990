import AdminMainCard from "@main-components/main-card";
import React from "react";
import BasicStats from "./components/BasicStats";

const Dashboard = () => {
  return (
    <AdminMainCard>
      <BasicStats />
    </AdminMainCard>
  );
};

export default Dashboard;
