import Layout from "@main-components/layout";
import AccountCreate from "@main/accounts/create";
import AccountEdit from "@main/accounts/edit";
import AccountList from "@main/accounts/list";
import AccountShow from "@main/accounts/show";
import AdminRoleCreate from "@main/admin-roles/create";
import AdminRoleEdit from "@main/admin-roles/edit";
import AdminRoleList from "@main/admin-roles/list";
import AdminRoleShow from "@main/admin-roles/show";
import CarBrandCreate from "@main/car-brands/create";
import CarBrandEdit from "@main/car-brands/edit";
import CarBrandList from "@main/car-brands/list";
import CarBrandShow from "@main/car-brands/show";
import Dashboard from "@main/dashboard";
import {DriverEdit} from "@main/drivers/edit";
import DriverList from "@main/drivers/list";
import DriverShow from "@main/drivers/show";

import Login from "@main/login";
import PassengerEdit from "@main/passengers/edit";
import PassengerList from "@main/passengers/list";
import PassengerShow from "@main/passengers/show";
import AuthProvider from "@misc/auth-provider";
import customRoutes from "@misc/custom-routes";
import DataProvider from "@misc/data-provider";
import TranslationProvider from "@misc/translation-provider";
import theme from "@theme";
import {createBrowserHistory as createHistory} from "history";
import * as React from "react";
import {Admin, Resource} from "react-admin";
import DriverEarningsList from "@main/drivers-earnings/list";
import DriverEarningsShow from "@main/drivers-earnings/show";

export const apiUrl =
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_API_URL
        : process.env.REACT_APP_API_URL;

function App() {
    const history = createHistory({
        basename: process.env.PUBLIC_URL,
    });

    const authProvider = AuthProvider(`${apiUrl}/login`);
    const dataProvider = DataProvider(apiUrl);
    return (
        <React.Fragment>
            <Admin
                theme={theme}
                history={history}
                authProvider={authProvider}
                dataProvider={dataProvider}
                loginPage={Login}
                layout={Layout}
                dashboard={Dashboard}
                i18nProvider={TranslationProvider}
                customRoutes={customRoutes}
            >
                {(permissions) => {
                    return [
                        <Resource
                            name="drivers"
                            options={{label: "Conductores"}}
                            list={DriverList}
                            show={DriverShow}
                            edit={DriverEdit as any}
                        />,
                        <Resource
                            name="drivers_earnings"
                            options={{label: "Ganancias conductores"}}
                            list={DriverEarningsList}
                            show={DriverEarningsShow}
                        />,
                        <Resource
                            name="passengers"
                            options={{label: "Pasajeros"}}
                            list={PassengerList}
                            show={PassengerShow}
                            edit={PassengerEdit as any}
                        />,

                        <Resource
                            name="car-brands"
                            options={{label: "Marca de vehiculos"}}
                            list={CarBrandList}
                            show={CarBrandShow}
                            edit={CarBrandEdit}
                            create={CarBrandCreate}
                        />,

                        <Resource
                            name="admin-roles"
                            options={{label: "Roles administrativos"}}
                            list={AdminRoleList}
                            show={AdminRoleShow}
                            edit={AdminRoleEdit}
                            create={AdminRoleCreate}
                        />,

                        <Resource
                            name="accounts"
                            options={{label: "Cuentas administrativas"}}
                            list={AccountList}
                            show={AccountShow}
                            edit={AccountEdit}
                            create={AccountCreate}
                        />,

                        <Resource name="configurations/search"/>,
                        <Resource name="configurations/driver-fees"/>,
                        <Resource name="configurations/commissions"/>,
                        <Resource name="stats/basic"/>,
                        <Resource name="stats/signup"/>,
                    ];
                }}
            </Admin>
        </React.Fragment>
    );
}

export default App;
