import { Typography } from "@material-ui/core";
import { permissionsConstantsDescriptions } from "@misc/auth-provider/permissions";
import React from "react";
import {
  BooleanInput,
  CheckboxGroupInput,
  Create,
  FormDataConsumer,
  required,
  SimpleForm,
  TextInput,
} from "react-admin";

export const validateRoleForm = (values) => {
  let errors: any = {};
  if (!values.name) {
    errors.name = "Por favor asigna un nombre";
  }
  if (
    values.isSuperAdmin == false &&
    (!values.permissions ||
      (values.permissions && values.permissions.length == 0))
  ) {
    errors.permissions = "Por favor selecciona al menos un permiso";
  }

  return errors;
};

export default function AdminRoleCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm validate={validateRoleForm}>
        <TextInput source="name" label="Nombre" validate={required()} />
        <BooleanInput
          defaultValue={false}
          label="Super admin"
          source="isSuperAdmin"
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !formData.isSuperAdmin && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1">Permisos</Typography>
                {permissionsConstantsDescriptions.map((g) => {
                  return (
                    <React.Fragment>
                      <CheckboxGroupInput
                        {...rest}
                        options={{ fullWidth: true }}
                        label={g.group}
                        source="permissions"
                        choices={Object.values(
                          Object.keys(g.elements).map((c) => ({
                            id: c,
                            name: g.elements[c],
                          }))
                        )}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}
