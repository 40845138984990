import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, {useState} from "react";
import {CardActions, useNotify, useRefresh} from "react-admin";
import {createRequest} from "@misc/data-provider";
import {Form} from "react-final-form";

const BlockButton = (props) => {
    const [openAlert, setOpenAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const title = props.record.blocked ? "Desbloquear cuenta" : "Bloquear cuenta"

    return (
        <span>
      <Button
          {...props}
          disabled={isLoading || props.disabled}
          onClick={() => setOpenAlert(true)}
          variant="contained"
          color={props.record.blocked ? "primary" : "danger"}
          style={{margin: 0, color: "white !important", backgroundColor: props.record.blocked ? "green" : "red"}}
      >
      {title}
          {isLoading && (
              <CircularProgress
                  size={20}
                  style={{marginLeft: 10, color: "white"}}
              />
          )}
      </Button>

      <Dialog
          open={openAlert}
          onClose={() => setOpenAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           ¿Estás seguro que desear {props.record.blocked ? "desbloquear" : "bloquear"} esta cuenta?
          </DialogContentText>
            <MyForm
                setOpenAlert={setOpenAlert}
                record={props.record}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
        </DialogContent>
      </Dialog>
    </span>
    );
};


function MyForm({setOpenAlert, record, isLoading, setIsLoading}) {
    const notify = useNotify();
    const refresh = useRefresh();

    const result = record.blocked ? "Se ha desbloqueado la cuenta correctamente" : "Se ha bloqueado la cuenta correctamente"


    const action = async (values) => {
        setOpenAlert(false);
        setIsLoading(true);
        createRequest(
            "drivers/toggle-block",
            {driverId: record.id, serviceType: record.carCategory, blocked: !record.blocked},
            "POST",
            true
        )
        .then((res) => {
            if (res.ok) {
                notify(
                    result
                );
            } else {
                notify("Ha ocurrido un error");
            }

            refresh();
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <Form
            onSubmit={action}
            render={({handleSubmit}) => (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                >

                    <CardActions
                        /* @ts-ignore */
                    >
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: "grey",
                                marginRight: 30,
                                color: "white",
                            }}
                            disabled={isLoading}
                            onClick={() => {
                                setOpenAlert(false);
                            }}
                        >
                            Cancelar
                        </Button>

                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={isLoading}
                        >
                            {isLoading && <CircularProgress
                                size={18}
                                thickness={2}
                            />}
                            {record.blocked ? "Desbloquear" : "Bloquear"}
                        </Button>
                    </CardActions>
                </form>
            )}
        />
    );
}

export default BlockButton;
