import { isArray, map } from "lodash";
import { useEffect, useState } from "react";
import { useGetPermissions } from "react-admin";

export const permissionsConstants = {

  LIST_PASSENGERS: "LIST_PASSENGERS",
  EDIT_PASSENGERS: "EDIT_PASSENGERS",
  BLOCK_PASSENGERS: "BLOCK_PASSENGERS",
  DELETE_PASSENGERS: "DELETE_PASSENGERS",
  EXPORT_PASSENGERS: "EXPORT_PASSENGERS",

  LIST_DRIVERS: "LIST_DRIVERS",
  EDIT_DRIVERS: "EDIT_DRIVERS",
  CHECK_DRIVER_DOCUMENTS: "CHECK_DRIVER_DOCUMENTS",
  BLOCK_DRIVERS: "BLOCK_DRIVERS",
  DELETE_DRIVERS: "DELETE_DRIVERS",
  EXPORT_DRIVERS: "EXPORT_DRIVERS",

  SEE_STATS: "SEE_STATS",

  LIST_ADMINS: "LIST_ADMINS",
  EDIT_ADMINS: "EDIT_ADMINS",
  CREATE_ADMINS: "CREATE_ADMINS",
  DELETE_ADMINS: "DELETE_ADMINS",

  LIST_ADMIN_ROLES: "LIST_ADMIN_ROLES",
  EDIT_ADMIN_ROLES: "EDIT_ADMIN_ROLES",
  CREATE_ADMIN_ROLES: "CREATE_ADMIN_ROLES",
  DELETE_ADMIN_ROLES: "DELETE_ADMIN_ROLES",


  LIST_CAR_BRAND: "LIST_CAR_BRAND",
  EDIT_CAR_BRAND: "EDIT_CAR_BRAND",
  CREATE_CAR_BRAND: "CREATE_CAR_BRAND",
  DELETE_CAR_BRAND: "DELETE_CAR_BRAND",
};

export const permissionsConstantsDescriptions = [

  {
    group: "Pasajeros",
    elements: {
      LIST_PASSENGERS: "Ver pasajeros",
      EDIT_PASSENGERS: "Editar pasajeros",
      BLOCK_PASSENGERS: "Bloquear pasajeros",
      DELETE_PASSENGERS: "Eliminar pasajeros",
      EXPORT_PASSENGERS: "Exportar pasajeros",
    },
  },
  {
    group: "Conductores",
    elements: {
      LIST_DRIVERS: "Ver conductores",
      EDIT_DRIVERS: "Editar conductores",
      CHECK_DRIVER_DOCUMENTS: "Aprobar/Rechazar documentos",
      BLOCK_DRIVERS: "Bloquear conductores",
      DELETE_DRIVERS: "Eliminar conductores",
      EXPORT_DRIVERS: "Exportar conductores",
    },
  },

  {
    group: "Estadísticas",
    elements: {
      SEE_STATS: "Ver estadísticas",
    },
  },
  {
    group: "Marca de vehículos",
    elements: {
      LIST_CAR_BRAND: "Ver marca de vehículos",
      EDIT_CAR_BRAND: "Editar marca de vehículos",
      CREATE_CAR_BRAND: "Crear marca de vehículos",
      DELETE_CAR_BRAND: "Eliminar marca de vehículos",
    },
  },
  {
    group: "Administración",
    elements: {
      LIST_ADMINS: "Ver cuentas administradoras",
      EDIT_ADMINS: "Editar cuentas administradoras",
      CREATE_ADMINS: "Crear cuentas administradoras",
      DELETE_ADMINS: "Eliminar cuentas administradoras",

      LIST_ADMIN_ROLES: "Ver roles administrativos",
      EDIT_ADMIN_ROLES: "Editar roles administrativos",
      CREATE_ADMIN_ROLES: "Crear roles administrativos",
      DELETE_ADMIN_ROLES: "Eliminar roles administrativos",
    },
  },
];

let permissionsConstantsD = map(permissionsConstantsDescriptions, "elements");

let flatten = {};
permissionsConstantsD.forEach((g) => {
  flatten = { ...flatten, ...g };
});

export const flattenPermissionsConstantsDescriptions = flatten;

export const checkPermission = (userPermissions, constant) => {
  return (
    userPermissions &&
    (userPermissions.isSuperAdmin ||
      (isArray(userPermissions.permissions) &&
        userPermissions.permissions.indexOf(constant) !== -1))
  );
};

export function useAdminPermissions() {
  const getPermission = useGetPermissions();

  const [loading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>(undefined);

  useEffect(() => {
    setIsLoading(true);

    getPermission().then((r) => {
      setData(r);
      setIsLoading(false);
    });
  }, [getPermission]);

  return {
    data,
    loading,
  };
}
