import {Typography} from "@material-ui/core";
import {get} from "lodash";
import React from "react";
import ModalImage from "react-modal-image";

export function DocumentField(props) {
    const {record, source, label, addLabel} = props;

    const isFile = (name) => {
        if (!name) return false;
        const images = ["png", "jpg", "jpeg"];
        let ext = name.split(".").pop();
        return images.indexOf(ext) == -1 && !name.includes("googleusercontent");
    };

    const isPdf = (name) => {
        if (!name) return false;
        const pdf = ["pdf"];
        let ext = name.split(".").pop();
        return pdf.indexOf(ext) == -1;
    };

    if (!record) return <div></div>;

    const field = get(record, source);

    let Comp: any = null;

    if (!isFile(field)) {
        Comp = () => (
            <ModalImage
                smallSrcSet={field}
                small={field}
                medium={field}
                large={field}
                alt={label}
                showRotate={true}
            />
        );
    } else {
        Comp = () => {
            const image = isPdf(field) ? "./img/pdf.png" : "./img/file.png";
            return (
                <a
                    href={field}
                    target="_BLANK"
                >
                    <img
                        src={image}
                        style={{width: 100, height: 100}}
                    />
                </a>
            );
        };
    }

    return (
        <div style={{maxWidth: 200, marginBottom: 20, marginRight: 20}}>
            {!field ? (
                <div>
                    <Typography variant="body1">No posee</Typography>
                </div>
            ) : (
                <Comp/>
            )}
        </div>
    );
}
