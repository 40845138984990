import { createMuiTheme } from "@material-ui/core/styles";

export const baseTheme = {
  sidebar: {
    width: 300,
  },

  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      light: "#51515c",
      main: "#000",
      dark: "#001064",
      contrastText: "#fff",
    },
    background: {
      default: "#fcfcfe",
    },
  },
  shape: {
    borderRadius: 5,
  },
  overrides: {
    RaMenu: {
      open: {
        width: 300,
      },
    },
    RaSidebar: {
      drawerPaper: {
        backgroundColor: "#08080805 !important",
      },
    },

    RaLayout: {
      content: {
        paddingLeft: "25px !important",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
      root: {
        border: "1px solid #e0e0e3",
        backgroundClip: "padding-box",
      },
    },
    MuiButton: {
     /* containedPrimary: {
        backgroundColor: "#fff",
        color: "#000",
        boxShadow: "none",
        margin: "2px",
      },
      '&:hover': {
        color: 'white',
      },*/
    },
    MuiAppBar: {
      colorSecondary: {
        color: "white !important",
        backgroundColor: "#000 !important",
        border: "none",
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#f5f5f5",
      },
      barColorPrimary: {
        backgroundColor: "#d7d7d7",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
  },
};
const theme = createMuiTheme(baseTheme);

export default theme;
