import Empty from "@main-components/empty";
import AdminMainCard from "@main-components/main-card";
import { Card } from "@material-ui/core";
import {
  checkPermission,
  permissionsConstants,
} from "@misc/auth-provider/permissions";
import React from "react";
import {
  CardActions,
  CreateButton,
  Datagrid,
  List,
  Responsive,
  SimpleList,
  TextField,
} from "react-admin";

export default function CarBrandList(props) {
  return (
    <List
      {...props}
      component={AdminMainCard}
      bulkActionButtons={false}
      actions={<ListActions permissions={props.permissions} />}
      sort={{ field: "name", order: "ASC" }}
      empty={
        <Card style={{ padding: 20 }}>
          <Empty
            hideActions={
              props.permissions &&
              !checkPermission(
                props.permissions,
                permissionsConstants.CREATE_CAR_BRAND
              )
            }
            subtitle={
              props.permissions &&
              !checkPermission(
                props.permissions,
                permissionsConstants.CREATE_CAR_BRAND
              )
                ? ""
                : undefined
            }
          />
        </Card>
      }
    >
      <Responsive
        small={
          <SimpleList
            primaryText={(record) => record.name}
            secondaryText={(record) => record.email}
            linkType="show"
          />
        }
        medium={
          <Datagrid style={{ backgroundColor: "white" }} rowClick="show">
            <TextField source="name" label="Nombre" />
          </Datagrid>
        }
      />
    </List>
  );
}

const ListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  hasCreate,
  permissions,
}: any) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
        variant: "contained",
      })}

    {hasCreate &&
      permissions &&
      checkPermission(permissions, permissionsConstants.CREATE_CAR_BRAND) && (
        <CreateButton
          basePath={basePath}
          resource={resource}
          variant="contained"
        />
      )}
  </CardActions>
);
