import {withStyles} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import {checkPermission, permissionsConstants,} from "@misc/auth-provider/permissions";
import React from "react";
import {
    EditButton,
    Responsive,
    Show,
    ShowController,
    SimpleShowLayout,
    Tab,
    TabbedShowLayout,
    TabbedShowLayoutTabs,
} from "react-admin";
import ApproveButton from "../components/ApproveButton";
import {CarInfo} from "./CarInfo";
import {Details} from "./Details";
import {Documents} from "./Documents";
import {ShowTitle} from "./ShowTitle";
import {SmallDevicesLayout} from "./SmallDevicesLayout";
import BlockButton from "@main/drivers/components/BlockButton";
import {BankAccount} from "@main/drivers/show/BankAccount";

const ShowActions = withStyles({
    button: {
        marginBottom: 10,
        color: "white",
    },
})(({basePath, data, resource, classes, permissions}: any) =>
    data ? (
        <Responsive
            small={<div></div>}
            medium={
                <CardActions
                    style={{
                        zIndex: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        flexFlow: "wrap",
                    }}
                >
                    {permissions &&
                        checkPermission(permissions, permissionsConstants.EDIT_DRIVERS) && (
                            <EditButton
                                label="Editar"
                                basePath={basePath}
                                resource={resource}
                                record={data}
                                variant="contained"
                                onClick={() => {
                                }}
                            />
                        )}


                    {permissions &&
                        checkPermission(
                            permissions,
                            permissionsConstants.CHECK_DRIVER_DOCUMENTS
                        ) && (
                            <ApproveButton
                                color="secondary"
                                variant="contained"
                                record={data}
                                className={classes.button}
                            />
                        )}

                    {permissions &&
                        checkPermission(
                            permissions,
                            permissionsConstants.CHECK_DRIVER_DOCUMENTS
                        ) && (
                            <BlockButton
                                color="secondary"
                                variant="contained"
                                record={data}
                                className={classes.button}
                            />
                        )}

                </CardActions>
            }
        />
    ) : (
        <div></div>
    )
);

export default function DriverShow(props) {
    return (
        <ShowController {...props}>
            {(controllerProps) => (
                <Show
                    {...props}
                    title={<ShowTitle/>}
                    actions={<ShowActions permissions={props.permissions}/>}
                >
                    <SimpleShowLayout>
                        <Responsive
                            small={<SmallDevicesLayout/>}
                            medium={
                                <TabbedShowLayout
                                    tabs={
                                        <TabbedShowLayoutTabs variant="scrollable" {...props} />
                                    }
                                >
                                    <Details
                                        wrapper={Tab}
                                        label="Detalle"
                                    />
                                    <Documents
                                        wrapper={Tab}
                                        label="Documentos"
                                    />

                                    {controllerProps.record &&
                                        controllerProps.record.driverType === "OWNER" && (
                                            <CarInfo
                                                wrapper={Tab}
                                                label="Información del vehículo"
                                            />
                                        )}

                                    <BankAccount
                                        wrapper={Tab}
                                        label="Datos bancarios"
                                    />
                                </TabbedShowLayout>
                            }
                        />
                    </SimpleShowLayout>
                </Show>
            )}
        </ShowController>
    );
}
