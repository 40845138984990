import { Tab } from "@main-components/tabs";
import React from "react";
import { CarInfo } from "./CarInfo";
import { Details } from "./Details";
import { Documents } from "./Documents";

export function SmallDevicesLayout(props) {
  return (
    <div>
      <Details {...props} wrapper={Tab} label="Detalle" />
      <Documents
        {...props}
        wrapper={Tab}
        label="Documentos"
        classes={props.classes}
      />
      <CarInfo {...props} wrapper={Tab} label="Información del vehículo" />
    </div>
  );
}
